@import '~bootstrap/scss/bootstrap';
@import '~animate.css/animate';

//Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';

// OWL CAROUSEL
@import '~owl.carousel/dist/assets/owl.carousel';
@import '~owl.carousel/dist/assets/owl.theme.default';

//CHART.JS
@import '~chart.js/dist/Chart';

//DATATABLES
@import '~datatables.net-bs4/css/dataTables.bootstrap4';

@import 'styles';
@import 'media_query';
@import 'sidebar';

.iti__flag {background-image: url("/storage/assets/flags.png") !important;}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("/storage/assets/flags@2x.png") !important;}
}

