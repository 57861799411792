@media (max-width: 359.98px) {

    // GENERAL
    h6,
    p {
        font-size: 14px;
    }

    h5 {
        font-size: 16px;
    }

    h4 {
        font-size: 18px;
    }
    .greeting-name{
        font-size:18px;
    }
    .greeting-role{
        font-size:10px;
    }
    .greeting-info{
        padding:0.2rem !important;
    }

    .text-title {
        font-size: 30px !important;
    }

    .text-landing{
        font-size: 36px;
    }

    .rounded-fluid{
        border-radius:10px !important;
    }
    .rounded-50{
        border-radius: 30px !important;
    }

    //     NAVBAR
    .nav-text {
        font-size: 15px !important;
    }

    //     FOOTER
    footer {
        padding-top: 2rem !important;
        text-align: center;
        background-size: 150%;
    }

    //     HOME PAGE
    .btn-home {
        padding: 0.2rem 0.5rem !important;
    }

    #pagehome-landing {
        background: url('/storage/assets/landing_mobile_1.png') center center no-repeat;
        background-size: cover;
        animation: none;
        height: 80vh;
        min-height: 600px;
        padding-top: 1rem;
    }

    .pagehome-landing .row {
        justify-content: center;
    }

    #pagehome-greetings{
        margin-top: -5rem;
        padding: 0 !important;
        background:url('/storage/assets/meteor_pattern_new.png') no-repeat, transparent;
        background-position:45% 50%;
        background-size: 1200px;
    }

    #pagehome-headline{
        padding: 2rem 0 !important;
    }
    
    #pagehome-about{
        padding: 3rem 0 4rem 0 !important;
        background-size: 140%,140%,cover;
    }

    #pagehome-competition,
    #pagehome-insight{
        padding: 1rem 0 !important;
    }

    #pagehome-sponsor,
    #pagehome-media-partner {
        padding: 1.5rem 0 !important;
    }

    #pagehome-contact{
        padding: 2rem 0 2.5rem !important;
        background-position: 50% 0%;
        background-size: 2400px;
    }

    .column-support {
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
        margin-left: 0;
    }

    #pageabout{
        padding: 6rem 0 0 0;
    }

    #pageabout-about-aeo,
    #pageabout-about-bnec{
        padding: 1rem 0;
    }
    #pageabout-history{
        padding: 4rem 0;
        background-size: 150%,150%,cover;
    }
    #pageabout-about-binus{
        padding: 2rem 0;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (min-width: 360px) and (max-width: 575.98px) {

    // GENERAL
    h6,
    p {
        font-size: 16px;
    }

    h5 {
        font-size: 18px;
    }

    h4 {
        font-size: 20px;
    }

    .text-title {
        font-size: 40px !important;
    }

    .text-landing{
        font-size: 44px;
    }
    .rounded-fluid{
        border-radius:15px !important;
    }

    //     NAVBAR
    .nav-text {
        font-size: 15px !important;
    }

    //     FOOTER
    footer {
        padding-top: 2rem !important;
        text-align: center;
        background-size: 150%;
    }

    // PAGE HOME
    .btn-home {
        padding: 0.2rem 0.5rem !important;
    }

    #pagehome-landing {
        background: url('/storage/assets/landing_mobile_1.png') center center no-repeat;
        background-size: cover;
        animation: none;
        height: 60vh;
        min-height: 700px;
        padding-top: 1rem;
        animation: slider_mobile 7s infinite linear;
    }

    @keyframes slider_mobile {
        0% {
            background: url('/storage/assets/landing_mobile_1.png') center center no-repeat;
            background-size:cover;
        }
        25% {
            background: url('/storage/assets/landing_mobile_2.png') center center no-repeat;
            background-size:cover;
        }
        90% {
            background: url('/storage/assets/landing_mobile_1.png') center center no-repeat;
            background-size:cover;
        }
    }

    .pagehome-landing .row {
        justify-content: center;
    }

    #pagehome-greetings{
        margin-top: -5rem;
        padding: 0 !important;
        background:url('/storage/assets/meteor_pattern_new.png') no-repeat, transparent;
        background-position:45% 50%;
        background-size: 1500px;
    }
    .greeting-name{
        font-size:22px;
    }
    .greeting-role{
        font-size:14px;
    }
    .greeting-info{
        padding:0.2rem !important;
    }

    #pagehome-about{
        padding: 3rem 0 4rem 0 !important;
        background-size: 150%,150%,cover;
    }

    #pagehome-headline{
        padding: 2rem 0 !important;
    }

    #pagehome-competition,
    #pagehome-insight{
        padding: 1rem 0 !important;
    }

    #pagehome-sponsor{
        padding: 1.5rem 0 0 0 !important;
    }
    #pagehome-media-partner {
        padding: 0 0 1rem 0 !important;
    }

    #pagehome-contact{
        padding: 2.5rem 0 !important;
        background-position: 50% 0%;
        background-size: 2300px;
    }

    .column-support {
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
        margin-left: 0;
    }

    #pageabout{
        padding: 6rem 0 0 0;
    }
    #pageabout-about-aeo,
    #pageabout-about-bnec{
        padding: 1rem 0;
    }

    #pageabout-about-binus{
        padding:2rem 0;
    }
    #pageabout-history{
        padding: 4rem 0;
        background-size: 150%,150%,cover;
    }
}

/* Small devices (Landscape phones, less than 768px) */
@media (min-width: 576px) and (max-width: 767.98px) {

    // GENERAL
    h6,
    p {
        font-size: 16px;
    }

    h5 {
        font-size: 18px;
    }

    h4 {
        font-size: 20px;
    }

    .greeting-name{
        font-size:28px;
    }
    .greeting-role{
        font-size:20px;
    }

    .text-landing{
        font-size: 48px;
    }

    //     NAVBAR
    .nav-text {
        font-size: 15px !important;
    }

    //     FOOTER
    footer {
        padding-top: 2rem !important;
        text-align: center;
    }

    //     PAGE HOME
    .btn-home {
        padding: 0.2rem 0.5rem !important;
    }

    #pagehome-landing {
        background: url('/storage/assets/landing_mobile_1.png') center center no-repeat;
        background-size: cover;
        height: 110vh;
        min-height: 600px;
        padding-top: 2rem;
        animation: slider_mobile 7s infinite linear;
    }

    @keyframes slider_mobile {
        0% {
            background: url('/storage/assets/landing_mobile_1.png') center center no-repeat;
            background-size:cover;
        }
        25% {
            background: url('/storage/assets/landing_mobile_2.png') center center no-repeat;
            background-size:cover;
        }
        90% {
            background: url('/storage/assets/landing_mobile_1.png') center center no-repeat;
            background-size:cover;
        }
    }

    .pagehome-landing .row {
        justify-content: center;
    }

    #pagehome-greetings{
        margin-top: -5rem;
        padding: 0 !important;
        background:url('/storage/assets/meteor_pattern_new.png') no-repeat, transparent;
        background-position:45% 50%;
        background-size: 1200px;
    }

    #pagehome-about{
        padding: 4rem 0 !important;
        background-size: 150%,150%,cover;
    }

    #pagehome-headline{
        padding: 1rem 0 !important;
    }

    #pagehome-competition,
    #pagehome-insight{
        padding: 2rem 0 !important;
    }

    #pagehome-sponsor,
    #pagehome-media-partner {
        padding: 1rem 0 !important;
    }

    #pagehome-contact{
        padding: 2rem 0 3rem !important;
        background-position: 50% 0%;
        background-size: 2300px;
    }

    #pageabout{
        padding: 7rem 0 0 0;
    }
    #pageabout-about-aeo,
    #pageabout-about-bnec{
        padding: 1rem 0;
    }
    #pageabout-about-binus{
        padding:2rem 0;
    }
    #pageabout-history{
        padding: 4rem 0;
        background-size: 150%,150%,cover;
    }

}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {

    // GENERAL
    h6,
    p {
        font-size: 16px;
    }

    h5 {
        font-size: 18px;
    }

    h4 {
        font-size: 20px;
    }

    .text-title {
        font-size: 48px !important;
    }

    .text-landing{
        font-size: 56px;
    }

    // NAVBAR
    .nav-text {
        font-size: 15px !important;
    }

    //     FOOTER
    footer {
        padding-top: 4rem !important;
        text-align: center;
    }

    // PAGE HOME
    .btn-home {
        padding: 0.2rem 0.5rem !important;
    }

    #pagehome-landing {
        background: url('/storage/assets/landing_mobile_1.png') center center no-repeat;
        background-size: 100%, cover;
        height: 60vh;
        min-height: 550px;
        padding-top: 2rem;
        animation: slider_mobile 5s infinite linear;
    }

    @keyframes slider_mobile {
        0% {
            background: url('/storage/assets/landing_mobile_1.png') center center no-repeat;
            background-size:cover;
        }
        25% {
            background: url('/storage/assets/landing_mobile_2.png') center center no-repeat;
            background-size:cover;
        }
        90% {
            background: url('/storage/assets/landing_mobile_1.png') center center no-repeat;
            background-size:cover;
        }
    }

    .pagehome-landing .row {
        justify-content: center;
    }

    #pagehome-greetings{
        margin: -5rem 0 2rem 0;
        padding: 0 !important;
        background:url('/storage/assets/meteor_pattern_new.png') no-repeat, transparent;
        background-position:45% 50%;
        background-size: 1200px;
    }

    .greeting-name{
        font-size:36px;
    }
    .greeting-role{
        font-size:22px;
    }

    #pagehome-about{
        padding: 6rem 0 !important;
        background-size: 120%,120%,cover;
    }

    #pagehome-headline{
        padding: 3rem 0 !important;
    }

    #pagehome-competition,
    #pagehome-insight{
        padding: 2rem 0 !important;
    }

    #pagehome-sponsor,
    #pagehome-media-partner {
        padding: 1rem 0 !important;
    }

    #pagehome-contact{
        padding: 2rem 0 3rem !important;
        background-position: center center;
        background-size: 1600px;
    }

    #pageabout{
        padding: 7rem 0 0 0;
    }
    #pageabout-about-aeo,
    #pageabout-about-bnec{
        padding: 2rem 0;
    }
    #pageabout-about-binus{
        padding:2rem 0;
    }
    #pageabout-history{
        padding: 5rem 0;
        background-size: 120%,120%,cover;
    }

}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {

    // GENERAL
    .text-title {
        font-size: 54px !important;
    }

    .text-landing{
        font-size: 67px;
    }

    // FOOTER
    footer {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }

    // HOME PAGE
    .pagehome-landing {
        height: 60vh !important;
        min-height: 500px !important;
    }

    #pagehome-greetings{
        padding: 6rem 0 3rem 0 !important;
        background-position: center top, 90% 50%;
        background-size: 100%,1100px;
    }

    #pagehome-competition,
    #pagehome-insight{
        padding: 2rem 0 !important;
    }

    #pagehome-sponsor,
    #pagehome-media-partner {
        padding: 1rem 0 !important;
    }

    #pagehome-contact{
        padding: 2rem 0 3rem !important;
        background-position: center center;
        background-size: 1500px;
    }
}


@media (min-width: 2000px) {
    footer {
        padding-top: 10rem !important;
    }
    #pageabout-history,#pagehome-about{
        padding:10rem 0 !important;
    }
    #pagehome-greetings{
        padding:13rem 0 0 0 !important;
    }
}

@media (min-width: 2500px) {
    footer {
        padding-top: 13rem !important;
    }
    #pageabout-history,#pagehome-about{
        padding:13rem 0 !important;
    }
}
