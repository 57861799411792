// GENERAL
@font-face {
    font-family: 'vision-bold';
    font-style: normal;
    font-weight: bold;
    src: url('/storage/fonts/vision-bold-webfont.woff') format("woff");
}

@font-face {
    font-family: 'vision-regular';
    font-style: normal;
    font-weight: normal;
    src: url('/storage/fonts/vision-webfont.woff') format("woff");
}

@font-face {
    font-family: 'quantum';
    font-style: normal;
    font-weight: normal;
    src: url('/storage/fonts/Quantum.otf') format("opentype");
}

*{
    font-family: 'vision-regular';
}

h1,h2,h3,h4,h5,h6{
    font-family: 'vision-bold' !important;
}

.bg-gradient {
    background-image: linear-gradient(115deg,#1A8AAB, #78C9B0);
}

.bg-purple {
    background-color:#1A8AAB !important;
    color: #ffffff;
}
.bg-blue {
    background-color: #78C9B0 !important;
    color: #ffffff;
}

.hover-purple-button {
    border-radius: 50px !important;
    transition: 200ms ease;

}

.hover-purple-button:hover {
    color: white !important;
    background-color: #1A8AAB !important;
}

.text-title {
    font-size: 44px;
    color:#1A8AAB !important;
}

.title-line {
    width: 80px;
    height: 3px;
    background: #78C9B0;
    margin-bottom: 0.5rem;
}

.text-purple {
    color: #1A8AAB;
}

.text-light-blue {
    color: #78C9B0;
}

.rounded-20 {
    border-radius: 20px !important;
}

.rounded-50 {
    border-radius: 50px !important;
}

.border-purple {
    border: 2px solid #1A8AAB !important;
}

.bg-transparent {
    background: transparent !important;
}

.color-white {
    color: white !important;
}

.card-faq{
    border-radius: 10px;
}

.card-header-faq {
    background-image: linear-gradient(115deg,#1A8AAB, #78C9B0);
    padding: 0.5rem 0;
    border-radius: 10px !important;
}

.text-landing {
    font-size: 72px;
}

.rounded-fluid {
    border-radius: 20px !important;
}

// PRELOADER
.preloader {
    position: fixed;
    z-index: 999999;
    background: black;
    width: 100%;
    height: 100%;
    transition: 100ms ease-out;
}


.loader {
    border: 0 solid transparent;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    position: absolute;
    top: calc(50vh - 75px);
    left: calc(50vw - 75px);
    transform: rotate(-10deg);
    -webkit-animation: mover 1.1s infinite alternate;
    animation: mover 1.1s infinite;
}

@-webkit-keyframes mover {
    0% { transform: translate(0px,70vh);
    }
    20% { transform: translate(0px,0px);
    }
    30% { transform: rotate(-150deg); 
    }
    40% { transform: rotate(-190deg); 
    }
    60% { transform: rotate(-370deg); 
    }
    100% { transform: translate(0px,-70vh);
    }
}
@keyframes mover {
    0% { transform: translate(0px,70vh);
    }
    20% { transform: translate(0px,0px); 
    }
    30% { transform: rotate(-150deg); 
    }
    40% { transform: rotate(-190deg); 
    }
    60% { transform: rotate(-370deg); 
    }
    100% { transform: translate(0px,-70vh); 
    }
}

//  NAVBAR
.navbar-home {
    background-image: linear-gradient(115deg, #1A8AAB, #78C9B0);
    transition: 250ms ease-in-out;
}

.nav-text {
    color: white !important;
    font-size: 18px;
}

.nav-button {
    transition: 300ms ease-in-out;
    border-radius: 50px !important;
}

.nav-button:hover {
    color: #1A8AAB !important;
    background: white !important;
}

//  FOOTER
footer {
    background: url('/storage/assets/divider_up_light.png') center top no-repeat, 
            url('/storage/assets/space_footer.png') center center;
    background-size: 100%, cover;
    padding-top: 6rem;
}

// HOME PAGE
#pagehome-landing {
    height: 60vh;
    min-height: 700px;
    background: url('/storage/assets/landing_1.png') center center no-repeat;
    background-size: cover;
    animation: slider 5s infinite linear;
    position: relative;
}

@keyframes slider {
    0% {
        background: url('/storage/assets/landing_1.png') center center no-repeat;
        background-size:cover;
    }
    25% {
        background: url('/storage/assets/landing_2.png') center center no-repeat;
        background-size:cover;
    }
    90% {
        background: url('/storage/assets/landing_1.png') center center no-repeat;
        background-size:cover;
    }
}

#pagehome-landing::after{
    content:'';
    display: block;
    width: 100%;
    height: 100%;
    background-image:linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,1));
    position: absolute;
    bottom: 0;
}

#pagehome-landing .container{
    z-index: 1;
    position: relative;
}

.btn-home {
    color: white !important;
    background: transparent !important;
    border-radius: 50px !important;
    transition: 500ms ease-in !important;
    border: 1px solid white !important;
}

.btn-home:hover {
    color: white !important;
    background-color: #1A8AAB !important;
}

#pagehome-greetings {
    background: url('/storage/assets/divider_up_black.png') no-repeat,
        url('/storage/assets/meteor_pattern_new.png') no-repeat;
    background-position: center top, 110% 80%;
    background-size: 100%, 1200px;
    padding: 7rem 0 4rem 0;
}

#pagehome-about {
    padding: 8rem 0;
    background: url('/storage/assets/divider_down_light.png') center bottom no-repeat,
        url('/storage/assets/divider_up_white.png') center top no-repeat,
        url('/storage/assets/space_footer.png') center center no-repeat;
    background-size: 100%, 100%, cover;
}

#pagehome-headline,
#pagehome-competition,
#pagehome-insight
 {
    padding: 3rem 0;
    background: #f8f9fa;
}


#pagehome-sponsor {
    background: #f8f9fa;
    padding: 3rem 0 2rem 0;
}

#pagehome-media-partner {
    background: #f8f9fa;
    padding: 2rem 0 3rem 0;
}

#pagehome-contact {
    padding: 4rem 0 5rem 0;
    background: url('/storage/assets/saturn_pattern_new.png') no-repeat, #f8f9fa;
    background-position: 40% 50%;
    background-size: 1550px;
}

// ABOUT US PAGE
#pageabout {
    padding: 6rem 0 3rem 0;
    background: #f8f9fa;
    background-position: 50% 50%;
    background-size: 2100px;
}

#pageabout-section1{
    background: url('/storage/assets/saturn_pattern_new.png') no-repeat;
    background-position: 50% 50%;
    background-size: 2700px;
}
#pageabout-section2{
    background: url('/storage/assets/meteor_pattern_new.png') no-repeat;
    background-position: 70% 50%;
    background-size: 2400px;
}

#pageabout #opening {
    padding-bottom: 2rem;
}

#pageabout-about-bnec{
    padding: 1rem 0;
}

#pageabout-about-aeo{
    padding:2rem 0;
}

#pageabout-about-binus
{
    padding: 4rem 0;
}

#pageabout-history {
    padding: 8rem 0;
    background: #f8f9fa;
    background: url('/storage/assets/divider_down_light.png') center bottom no-repeat,
        url('/storage/assets/divider_up_light.png') center top no-repeat,
        url('/storage/assets/space_footer.png') center center no-repeat;
    background-size: 100%, 100%, cover;
}

// FAQ PAGE
#pagefaq {
    padding: 6rem 0 1rem 0;
    background:url('/storage/assets/saturn_pattern_new.png') center center no-repeat, #f8f9fa;
    background-size: 1800px;
}

#pagefaq-content {
    padding: 3rem 0;
}

// LOGIN PAGE
#pagelogin-login {
    padding: 6rem 0 4rem 0;
    background:url('/storage/assets/saturn_pattern_new.png') center center no-repeat, #f8f9fa;
    background-size: 1200px;
}

//  REGISTER PAGE
#pageregister-register {
    padding: 6rem 0 5rem 0;
    background:url('/storage/assets/saturn_pattern_new.png') center center no-repeat, #f8f9fa;
    background-size: 3000px;
}

// DASHBOARD STEP PAGE
#pagedashboard-dashboard-step {
    padding: 6rem 0 5rem 0;
    background: #f8f9fa;
}


// CHOOSE COMPETITION FIELD AND SLOT PAGE
#pagedashboard-competition_slot {
    padding: 6rem 0;
    background: #f8f9fa;
}

// TRANSFER PAYMENT PAGE
#pagedashboard-transfer-proof {
    padding: 6rem 0;
    background: #f8f9fa;
}

// INSTITUTION LOGO PAGE
#pagedashboard-update-institution-logo {
    padding: 6rem 0;
    background: #f8f9fa;
    background-position: 50% 50%;
    background-size: 600px;
}

// ADD PARTICIPANT PAGE
#pagedashboard-add-participant {
    padding: 6rem 0 5rem 0;
    background: #f8f9fa;
}

// RESET PASSWORD PAGE
#pagereset {
    padding: 6rem 0 5rem 0;
    background: #f8f9fa;
}

// VERIFY EMAIL
#pageverify {
    padding: 6rem 0 5rem 0;
    background: #f8f9fa;
}
#adminPage{
    padding: 6rem 0 5rem 0;
    background: #f8f9fa;
}


.card-deck .card {
    padding: 40px;
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center
}

.card-deck .card:before {
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, .13);
    z-index: 1;
    pointer-events: none
}

.card-deck .card .card-img-top {
    width: 100px;
    height: 100px;
    color: #fff;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50%;
    font-size: 40px;
    font-weight: 700;
    transition: 1s
}


.card-deck .card:nth-child(1):hover .card-img-top {
    box-shadow: 0 0 0 400px #f4bd47;
    background: #f4bd47;
}


.card-deck .card:nth-child(2):hover .card-img-top {
    box-shadow: 0 0 0 400px #f4bd47;
    background: #f4bd47;
}


.card-deck .card:hover .top-1 .card-img-top {
    box-shadow: 0 0 0 400px #8d4c9d;
    background: #8d4c9d;
}


.card-deck .card:hover .top-2 .card-img-top {
    box-shadow: 0 0 0 400px #f18f40;
    background: #f18f40;
}


.card-deck .card:hover .top-3 .card-img-top  {
    box-shadow: 0 0 0 400px #9D2F66;
    background: #9D2F66;
}

.card-deck .card:hover .top-4 .card-img-top  {
    box-shadow: 0 0 0 400px #7bc044;
    background: #7bc044;
}


.card-deck .card:hover .top-5 .card-img-top  {
    box-shadow: 0 0 0 400px #33BC97;
    background: #33BC97;
}

.card-deck .card:hover .top-6 .card-img-top  {
    box-shadow: 0 0 0 400px #72cacd;
    background: #72cacd;
}

.card-deck .card .card-body {
    z-index: 1;
    transition: .5s
}

.card-deck .card:hover .card-body {
    color: #fff
}

.card-deck .card:hover .card-body card-title {
    font-size: 20px;
    margin: 10px 0;
    padding: 0
}

.btn-radio {
    color: #fff;
    background-color:#1A8AAB;
    border-color:#1A8AAB;
}
.btn-radio:hover {
    color: #fff;
    background-color: #78C9B0;
    border-color:#78C9B0;
}
.btn-radio.focus,
.btn-radio:focus {
    -webkit-box-shadow: 0 0 0 0.2rem  #78C9B0;
    background-color:  #78C9B0;
    box-shadow: 0 0 0 0.2rem  #78C9B0;
}
.btn-radio:not(:disabled):not(.disabled).active,
.btn-radio:not(:disabled):not(.disabled):active,
.show > .btn-radio.dropdown-toggle {
    color: #fff;
    background-color:  #78C9B0 !important;
    border-color:  #78C9B0 !important;
}
.btn-radio:not(:disabled):not(.disabled).active:focus,
.btn-radio:not(:disabled):not(.disabled):active:focus,
.show > .btn-radio.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem  #78C9B0 !important;
    box-shadow: 0 0 0 0.2rem  #78C9B0 !important;
}

//DATATABLES
.page-item.active .page-link {
    color: #fff;
    background-color:#1A8AAB;
    border-color:#1A8AAB;
}
.page-link {
    color:#1A8AAB;
}
.page-link:hover {
    color:#1A8AAB;
}
.page-link:focus {
    box-shadow: none;
}

.dataTables_length {
    text-align: left !important;
}
.dataTables_filter {
    @include media-breakpoint-down(sm) {
        text-align: left !important;
    }
}
.dataTables_info {
    padding-top: 0 !important;
    text-align: left !important;
}
table.dataTable.dtr-inline.collapsed.table-sm > tbody > tr > td:first-child::before,
table.dataTable.dtr-inline.collapsed.table-sm > tbody > tr > th:first-child::before {
    top: 50% !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    @include media-breakpoint-down(sm) {
        justify-content: loadert !important;
    }
}

.btn.collapsed i.fa-chevron-up{
    transform: rotate(180deg) !important;
    transition: 500ms ease;
  }

.btn i.fa-chevron-up{
    transition: 500ms ease;
  }

.greeting-name{
    color:#1A8AAB;
}

.card-dashboard-hover{
    padding: 1.5rem 0; 
    margin: 1.5rem 0 0.5rem 0;
    transition: 0.5s;
}

.card-dashboard-hover:hover{
    background: black;
    color:white !important;
    transform: scale(1.05);
}

.btnSubmit:disabled{
    opacity: 100 !important;
}
